import clsx from "classnames"

export interface TextProps {
  text: string
  format?: Array<"italic" | "bold" | "strikethrough" | "underline">
}

export const Text = ({ text, format }: TextProps) => {
  const className = clsx({
    "font-bold": format?.includes("bold"),
    italic: format?.includes("italic"),
    "line-through": format?.includes("strikethrough"),
    underline: format?.includes("underline"),
  })
  return <span className={className}>{text}</span>
}
